<template>

  <div class="dashboard">

    <div class="row">
      <div class="col-12 section-head my-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="section-head-title p-3 ps-0 mb-0">
              Conoce las cifras más importantes de <strong>Beneficios</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
                Filtrar por periodo:
              </div>
              <div class="col-12 col-lg-9 col-xl-8">
                <div class="row">
                  <div class="col-6">
                    <InputDate 
                      :max="range.date_end"
                      placeHolder="Inicio"
                      @dateEmit="captureDateStart"
                    />
                  </div>
                  <div class="col-6">
                    <InputDate 
                      :min="range.date_start" 
                      placeHolder="Término"
                      @dateEmit="captureDateEnd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">

            <!--CARD NUMBERS-->
            <div class="card card-numbers border-round-10 card-shadow mb-3">
              <div class="card-body">
                <div class="card-numbers-head d-flex align-items-center color-main">
                  <img class="card-numbers-head-icon" src="@/assets/img/benefit.svg"/>
                  <div class="card-numbers-head-num">{{ dash_data.total_beneficiados }}</div>
                  <div class="card-numbers-head-text">
                    Usuarios <br>
                    beneficiados
                  </div>
                </div>
                <div class="card-numbers-text mt-3">
                  {{ intro_text }}
                </div>
              </div>
            </div>

            <!--CARD PIE-->
            <Loading 
              v-if="loading"
              pixeles="150px"
            />
            <template v-if="!loading">         
              <div 
                class="card card-pie border-round-10 card-shadow mb-3 mb-lg-0" 
                v-show="showChart()">
                <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="card-pie-graph">
                          <apexchart
                            :key="key_chart"
                            :options="chartOptions" 
                            :series="series">
                          </apexchart>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card-pie-legend d-flex flex-column justify-content-center align-items-start ps-3" style="margin-top:10px;">
                          <h5 class="mb-3 card-bars-title">Estadísticas por estado</h5>
                          <div 
                            class="card-pie-legend-text d-flex align-items-center" 
                            v-for="(rela, i) in dash_data.solicitudes_tipo" 
                            :key="i">
                            <div 
                              class="card-pie-legend-dot" 
                              :style="`background-color: ${chartOptions.colors[i]};`">  
                            </div>
                            {{ rela.nombre_solicitud }}
                          </div>
                        </div>                  
                      </div>
                    </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">

              <!--CARD BARS-->
              <Loading 
                v-if="loading"
                pixeles="150px"
              />
              <template v-if="!loading">          
                <div 
                  class="col-12 col-xl-6 mb-3" 
                  v-for="(data, i) in dash_data.solicitudes_estado" :key="i">
                  <div class="card card-shadow card-bars border-round-10">
                    <div class="card-body">
                      <div class="card-bars-title">{{ data.nombre }}</div>
                      <div class="card-bars-count d-flex align-items-center my-2">
                        <div class="card-bars-count-num pe-2">{{ data.cantidad_solicitudes }}</div>
                        <div class="card-bars-count-val">
                          Solicitudes
                        </div>
                      </div>
                      <div class="card-bars-bar">
                        <div 
                          class="card-bars-bar-back bg-blue-gray">
                        </div>
                        <div 
                          class="card-bars-bar-front bg-blue"
                          :style="`width:${data.porcentaje_solicitudes}%;`">
                        </div>
                      </div>
                    </div>
                  </div>      
                </div>
              </template>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import moment from "moment";
  import { mapState, mapActions } from "vuex";
  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";

  export default {
    components: {
    //FF
    InputDate,
    Loading
  },
    data() {
      return {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        //series: [0, 0, 0],
        chartOptions: {
          chart: {
            type: "donut",
            fontFamily: "Product Sans Bold",
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 1,
              color: "#0072f7",
              opacity: 0.35,
            },
          },
          dataLabels: {
            style: {
              colors: ["#fff"],
              fontSize: "14px",
              fontWeight: "bold",
            },
            dropShadow: {
              top: 0,
              left: 0,
              blur: 1,
              color: "#0072f7",
              opacity: 0.45,
            },
          },
          legend: {
            show: false,
          },
          stroke: {
            show: false,
            curve: "smooth",
            lineCap: "butt",
            colors: ["#fff"],
            width: 2,
            dashArray: 0,
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
              customScale: 1,
              dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10,
              },
              donut: {
                size: "50%",
                background: "transparent",
              },
            },
          },
          colors: ["#D73B6C", "#F6C700", "#23BE61"],
        },
        //dash_data: {},
        key_dash: 1,
        valores: [],
        value_colors: [],
        

        //FF
        intro_text: "Beneficios te permite acercar y agilizar el proceso de solicitudes. Te recomendamos revisar constantemente las estadísticas para llevar un registro de ellas y conocer sus estados.",
        loading: true,
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },
    async created() {
      await this.getDash();
    },
    watch: {
      "range.date_start"() {
          this.getDash();
      },
      "range.date_end"() {
          this.getDash();    
      },
    },
    computed: {
      ...mapState("benefitModule", [
      "series",
      "v_chartOptions",
      "key_chart",
      "dash_data"
    ]),
    },
    methods: {
      ...mapActions("benefitModule", ["getDashboard"]),
      
      showChart() {
        var suma = 0;
        for (let i = 0; i < this.series.length; i++) {
          const element = this.series[i];
          suma = suma + Number(element);
        }
        if (suma == 0) {
          return false;
        }
        if (suma != 0) {
          return true;
        }
      },
      async getDash() {
        this.loading = true;
        let data_ = {
              id_empresa: this.id_empresa,
              historico: this.range.date_start !== null || this.range.date_end !== null ? "1" : "0",
              fecha_inicio: this.range.date_start !== null ? this.range.mask_start : moment().startOf('month').format('YYYY-MM-DD'),
              fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
        }
        //console.log("revisando data_", data_);
        await this.getDashboard(data_);
        this.chartOptions.labels = this.v_chartOptions;
        this.loading = false;
      },
      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      },
    },
  };
</script>
